/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import React from 'react';

export const Help = function() {
    return (
        <svg>
            <g id="icon/action/help_outline_24px">
                <path
                    clipRule="evenodd"
                    d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM13 16V18H11V16H13ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM8 10C8 7.79 9.79 6 12 6C14.21 6 16 7.79 16 10C16 11.2829 15.21 11.9733 14.4408 12.6455C13.711 13.2833 13 13.9046 13 15H11C11 13.1787 11.9421 12.4566 12.7704 11.8217C13.4202 11.3236 14 10.8792 14 10C14 8.9 13.1 8 12 8C10.9 8 10 8.9 10 10H8Z"
                    fill="white"
                    fillOpacity="0.54"
                    fillRule="evenodd"
                    id="icon/action/help_outline_24px_2"/>
            </g>
        </svg>
    );
};

export const Linkto = function() {
    return (
        <svg>
            <path
                d="M5.74193 0.897461V3.01712H12.1193L0.903223 15.3005L2.26774 16.7949L13.4839 4.51148V11.4958H15.4193V0.897461H5.74193Z"
                fill="black"
                fillOpacity="0.54"/>
        </svg>
    );
};

export const Confirm = function() {
    return (
        <svg fill="none" height="24" viewBox="0 0 24 24" width="24"
            xmlns="http://www.w3.org/2000/svg">
            <g id="icon/action/check_circle_outline_24px">
                <path clipRule="evenodd" d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM10 14.17L16.59 7.58L18 9L10 17L6 13L7.41 11.59L10 14.17Z" fill="#00E676" fillRule="evenodd"
                    id="icon/action/check_circle_outline_24px_2"/>
            </g>
        </svg>
    );
};

export const Eye = function() {
    return (
        <svg
            fill="none"
            height="15"
            viewBox="0 0 22 15"
            width="22"
            xmlns="http://www.w3.org/2000/svg">
            <path
                clipRule="evenodd"
                d="M10.9997 0C6.55955 0 2.69505 2.45258 0.673804 6.07615C0.180346 6.9608 0.180347 8.0392 0.673805 8.92385C2.69505 12.5474 6.55955 15 10.9997 15C15.4399 15 19.3044 12.5474 21.3257 8.92385C21.8191 8.0392 21.8191 6.9608 21.3257 6.07615C19.3044 2.45258 15.4399 0 10.9997 0ZM10.9997 12.5C8.23973 12.5 5.99973 10.26 5.99973 7.5C5.99973 4.74 8.23973 2.5 10.9997 2.5C13.7597 2.5 15.9997 4.74 15.9997 7.5C15.9997 10.26 13.7597 12.5 10.9997 12.5ZM7.99973 7.5C7.99973 5.84 9.33973 4.5 10.9997 4.5C12.6597 4.5 13.9997 5.84 13.9997 7.5C13.9997 9.16 12.6597 10.5 10.9997 10.5C9.33973 10.5 7.99973 9.16 7.99973 7.5Z"
                fill="black"
                fillOpacity="0.54"
                fillRule="evenodd"/>
        </svg>
    );
};

export const Badge = ({size = 129}) => {
    return (
        <svg
            fill="none"
            viewBox="0 0 129 129"
            width={size}
            xmlns="http://www.w3.org/2000/svg">
            <path d="M82.0983 0.919998L92.5582 17.113L111.831 18.0583L112.807 37.3313L129 47.7912L120.217 64.96L129 82.0983L112.807 92.5582L111.862 111.831L92.5887 112.807L82.1288 129L64.96 120.217L47.8217 129L37.3618 112.807L18.0888 111.862L17.113 92.5887L0.920013 82.1288L9.70264 64.96L0.920013 47.8217L17.113 37.3618L18.0583 18.0888L37.3313 17.113L47.7912 0.919998L64.96 9.70262L82.0983 0.919998Z" fill="#00E676"/>
            <path d="M57.0008 87.0691L37.2094 67.2776L43.6743 60.8127L57.1227 74.2306L86.2762 45.9615L92.6497 52.5179L57.0008 87.0691Z" fill="white"/>
        </svg>

    );
};
