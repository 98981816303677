const energi = { // see https://github.com/iancoleman/bip39
    messagePrefix: 'Energi Signed Message:\n',
    bip32: {
        public: 0x03B8C856,
        private: 0xD7DC6E9F,
    },
    pubKeyHash: 0x21,
    scriptHash: 0x35,
    wif: 0x6a,
};

module.exports = {
    energi
};
